






import Vue from 'vue';
import Dashboard from '../../views/dashboard/Dashboard.vue';
export default Vue.extend({
  name: 'DashboardIndex',
  components: {
    Dashboard,
  },
});
