




















































































































































































































































































































































































































































import Estimate from '@/components/dashboard/Estimate.vue';
import { Auth } from '@/models/auth';
import { TokenService } from '@/services/token';
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import PreAlertForm from '../../components/dashboard/PreAlertForm.vue';
import TrackShipment from '../../components/dashboard/TrackShipment.vue';
export default Vue.extend({
  name: 'DashboardPage',
  components: {
    PreAlertForm,
    TrackShipment,
    Estimate
  },
  computed: {
    ...mapState('account', ['account']),

    loggedInUser(): Auth {
      return JSON.parse(localStorage.getItem('user')!);
    },

    screenWidth() {
      return window.innerWidth > 600;
    },
    screenTablets() {
      return window.innerWidth <= 1204;
    }
  },
  data() {
    return {
      token: new TokenService()
    };
  },
  methods: {
    ...mapActions({
      fetchShipments: 'shipment/fetchShipments',
      fetchPreAlerts: 'preAlert/fetchPreAlerts'
    }),

    goToPage(routeName: string) {
      this.$router.push({ name: routeName });
    },

    openPreAlertFormDialog() {
      (this as any).$refs.prealertform.open();
    },

    openTrachShipmentDialog() {
      (this as any).$refs.trackshipmentform.open();
    },

    openEstimateDialog() {
      (this as any).$refs.estimateform.open();
    },

    genPreAlert() {
      this.fetchPreAlerts();
    }
  },
  metaInfo() {
    return {
      title: 'Our Nation Express',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content:
            'Welome to Our Nation Express, the best courier service in Jamaica. We serve over 200 clients weekly, providing delivery services to Montego Bay and Kingston. Sign up now to enjoy our cheap rates!'
        }
      ]
    };
  }
});
